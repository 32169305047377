import React from "react"
import Img from 'gatsby-image'
import { graphql, StaticQuery, Link } from "gatsby"
import {
  Card,
} from "bloomer"

export default function Block(props) {
    return (
      <StaticQuery
        query={graphql`
          query BlockQuery {
            baralhocigano: file(relativePath: { eq: "blocks/baralhocigano.png" }) {
              childImageSharp {
                fluid(maxWidth: 500, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            tarotdoamor: file(relativePath: { eq: "blocks/tarotdoamor.png" }) {
              childImageSharp {
                fluid(maxWidth: 500, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            mapaastral: file(relativePath: { eq: "blocks/mapaastral.png" }) {
              childImageSharp {
                fluid(maxWidth: 500, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            tarotdasorte: file(relativePath: { eq: "blocks/tarotdasorte.png" }) {
              childImageSharp {
                fluid(maxWidth: 500, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            
          }
        `}
        render={data => (
          <Card>
              <Link to={props.link}>
                <Img fluid={data[props.img].childImageSharp.fluid} className="card-image" />
                <h3 className="card-footer card-footer-item">{props.title}</h3>
              </Link>
          </Card>
        )}
      />
    )

  
  
}

import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import {
  Section,
  Column,
} from "bloomer"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Formulario from "../components/formulario"
import AnjosForm from "../components/anjosForm"
import HoroscopoBlock from "../components/horoscopo-block"
import CompatibilidadeAmorosaBlock from "../components/compatibilidade-amorosa-block"

import { Columns } from "bloomer/lib/grid/Columns"

import Block from "../components/block"

const IndexPage = ( props ) => {
  const path = props.location?.pathname || ''
  return (
    <Layout path={path}>
      <SEO title="Horóscopo, Astrologia, Mapa Astral, Tarot , Oráculo e Esoterismo | Estrelaguia Guia" />
      
        <Columns className="columns is-mobile has-text-centered is-multiline">
          <Column isSize="1/2">
            <Block img="baralhocigano" title="Baralho Cigano" link='oraculo/baralho-cigano'></Block>
          </Column>
          <Column isSize="1/2">
            <Block img="tarotdoamor" title="Compatibilidade Amorosa" link='astrologia/compatibilidade-amorosa'></Block>
          </Column>
          <Column isSize="1/2">
            <Block img="mapaastral" title="Mapa Astral" link='mapa/astral/create'></Block>
          </Column>
          <Column isSize="1/2">
            <Block img="tarotdasorte" title="Tarot Da Sorte" link='oraculo/tarot-da-sorte'></Block>
          </Column>
          {/* <CompatibilidadeAmorosaBlock /> */}
          </Columns>
     
    </Layout>
  )
}

IndexPage.propTypes = {
  site: PropTypes.shape({
    siteMetadata: PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }),
  }),
}
export default IndexPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`

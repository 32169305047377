import React, { useState } from 'react';
import { navigate} from "gatsby"
import Sign from '../components/sign'
import {
  Column,
  Columns,
} from "bloomer"  

import {useHoroscopoData} from '../hooks/use-horoscopo-data'

const CompatibilidadeAmorosaBlock= () => {

  const data = useHoroscopoData()

  const [state, setState] = useState({
    userSign: "",
    partnerSign: "",
  });
  
  const handleUserSign = (sign) => {
    setState({
      ...state,
      userSign: sign,
    });
  };
  const handlePartnerSign = (sign) => {
    setState({
      ...state,
      partnerSign: sign,
    });

  };

  if(state.partnerSign && state.userSign){
    navigate(`/astrologia/compatibilidade-amorosa/${state.userSign}/${state.partnerSign}`);
  }

  

  return (
    <Column isSize="full" className="is-multiline compatibilidade">
      <Columns className="is-multiline is-mobile">
      <h2 className="is-full column card-header card-header-title">Seu signo: {state.userSign}</h2>
        {data.allMarkdownRemark.edges.map(({ node }) => (           
          <button 
            key={"userSign-"+node.frontmatter.mname}
            onClick={() => handleUserSign(node.frontmatter.mname)} 
            className={state.userSign===node.frontmatter.mname? 'is-active button is-small':'button is-small'}>
            <Sign size={1} icon={node.frontmatter.mname} title={node.frontmatter.name}></Sign>
            <h3>{node.frontmatter.name}</h3>
          </button>            
        ))}
      </Columns>
      <Columns className="is-multiline is-mobile">
      <h2 className="is-full column card-header card-header-title ">O signo dele(a): {state.partnerSign}</h2>
        {data.allMarkdownRemark.edges.map(({ node }) => (
              <button 
              key={`partnerSign-${node.frontmatter.mname}`}
              onClick={() => handlePartnerSign(node.frontmatter.mname)}
              className={state.partnerSign===node.frontmatter.mname? 'is-active button is-small':'button is-small'}>
                <Sign size={1} icon={node.frontmatter.mname} title={node.frontmatter.name}></Sign>
                <h3>{node.frontmatter.name}</h3>
              </button>
        ))}
      </Columns>
    </Column>
  )
}

export default CompatibilidadeAmorosaBlock;